<script setup>

const route = useRoute();
const router = useRouter();

 defineProps({
  array: Array,
});

const prevPageUrl = ref(null);

onMounted(()=>{
  let array, final;
  if (route.path.split("/").length === 3) {
    array = route.path.split("/");
    final = array.join("/");
  } else {
    array = route.path.split("/");
    array.pop();
    final = array.join("/");
  }
  prevPageUrl.value = final;
})

const toPath = (path) =>{
  router.replace(path)
}

</script>

<template>
  <div class="footerNav">
    <div
      v-for="(item, index) in array"
      :key="index"
      :class="{
        footerNav__item_active: route.path === `${prevPageUrl}/${item.path}`,
      }"
      class="footerNav__item"
      @click="toPath(item.path)"
    >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footerNav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0;

  &__item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(160, 160, 160, 0.2);
    margin: 0 2.5px;
    cursor: pointer;

    &_active {
      background-color: $black;
    }
  }
}
</style>
