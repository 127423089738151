<script setup>
import copytight from "~/utils/copyright";
import FooterNav from "~/components/layout/White/FooterNav.vue";
import { useMainStore } from "@/store";
import { useCategsStore } from "@/store/categs";
import { useWhiteStore } from "@/store/whitePages";
import Scrollbar from "smooth-scrollbar";

// const LanguageButton = defineAsyncComponent(() =>
//   import("~/components/global/LanguageButton.vue")
// );

const store = useMainStore();
const route = useRoute();
const router = useRouter();
const categs = useCategsStore();
const whiteStore = useWhiteStore();
const initAnim = useNuxtApp().$initAnim;

const mouse = ref({
  x: 0,
  y: 0,
});
const displayRight = ref(false);
const displayLeft = ref(false);

const mouseIsMoving = (e) => {
  mouse.value.x =
    e.pageX > window.innerWidth - 150 ? window.innerWidth - 150 : e.pageX;
  mouse.value.y =
    e.pageY > window.innerHeight - 120 ? window.innerHeight - 120 : e.pageY;

  displayLeft.value = e.pageX < window.innerWidth * 0.1;
  displayRight.value = e.pageX > window.innerWidth * 0.9;
};

const prevPage = ref(null);

const handlePrevPage = () => {
  let routesArray, finalPath;
  if (route.path.slice(-1) !== "/") {
    routesArray = route.path.split("/");
    routesArray.pop();
    finalPath = routesArray.join("/");
  } else {
    routesArray = route.path.split("/");
    routesArray.pop();
    routesArray.pop();
    finalPath = routesArray.join("/");
  }
  const result = categs.getPage(finalPath);

  prevPage.value = result;
};

const prevPageSlug = computed(() => {
  const pathArray = prevPage.value.path.split("/");
  return pathArray[pathArray.length - 1];
});

const whitePagesArray = computed(() =>
  whiteStore.getPagesArray(prevPageSlug.value)
);

const thisPageSlug = computed(() => {
  if (route.path.slice(-1) !== "/") {
    return route.path.split("/").pop();
  } else {
    const pathArray = route.path.split("/");
    return pathArray[pathArray.length - 2];
  }
});

const pathNext = computed(() => {
  try {
    let currentElemIndex;
    whitePagesArray.value.forEach((page, index) => {
      if (thisPageSlug.value === page.path) currentElemIndex = index;
    });

    return currentElemIndex + 1 === whitePagesArray.value.length
      ? `${prevPage.value.path}/${whitePagesArray.value[0].path}`
      : `${prevPage.value.path}/${
          whitePagesArray.value[currentElemIndex + 1].path
        }`;
  } catch (e) {
    return "/";
  }
});

const pathPrev = computed(() => {
  try {
    let currentElemIndex;
    whitePagesArray.value.forEach((page, index) => {
      if (thisPageSlug.value === page.path) currentElemIndex = index;
    });

    return currentElemIndex === 0
      ? `${prevPage.value.path}/${
          whitePagesArray.value[whitePagesArray.value.length - 1].path
        }`
      : `${prevPage.value.path}/${
          whitePagesArray.value[currentElemIndex - 1].path
        }`;
  } catch (e) {
    return "/";
  }
});

const handlePathPrev = (e) => {
  e.preventDefault();
  router.replace(pathPrev.value);
};

const handlePathNext = (e) => {
  e.preventDefault();
  router.replace(pathNext.value);
};

onMounted(() => {

  handlePrevPage();
  if (!store.isMobile) {
    const pageEl = document.querySelector(".white-page");
    if (!store.isMobile && pageEl) {
      Scrollbar.init(pageEl, { damping: 0.07 });
    }
    setTimeout(() => {
      initAnim();
    }, 1000);
  }

  window.addEventListener("mousemove", mouseIsMoving);
});

onUnmounted(() => {
  window.removeEventListener("mousemove", mouseIsMoving);
});

watch(
  () => route.fullPath,
  (newPath) => {
    const pageEl = document.querySelector(".white-page");
    if (!store.isMobile && pageEl) {
      Scrollbar.get(pageEl).scrollTo(0, 0, 1000);
    }
  }
);
</script>

<template>
  <div class="white">
    <!-- LOADER -->
    <transition name="fade">
      <div class="mainLoader" v-show="!store.windowLoaded">
        <p class="mainLoader__text">{{ $t("Loading") }}...</p>
      </div>
    </transition>
    <!-- LOADER END -->

    <header>
      <transition name="fade">
        <div class="backLink">
          <div class="backLink__link">
            <NuxtLink :to="prevPage?.path">
              {{ $t("Back_to") }}
              {{ prevPage && $t(prevPage.title).replace(/_/g, " ") }}
            </NuxtLink>
          </div>

          <div class="backLink__language">
            <!-- <LanguageButton /> -->
          </div>
        </div>
      </transition>
    </header>
    <!-- HEADER END -->

    <div class="white-page">
      <transition name="fade">
        <main>
          <NuxtPage />
        </main>
      </transition>

      <NuxtLink
        class="children__button children__button_left"
        :to="pathPrev"
        @click="(e) => handlePathPrev(e)"
      ></NuxtLink>
      <NuxtLink
        class="children__button children__button_right"
        :to="pathNext"
        @click="(e) => handlePathNext(e)"
      ></NuxtLink>

      <footer class="footer">
        <p class="copyright">{{ copytight() }} All rights reserved.</p>
      </footer>

      <!-- FOOTER -->
      <transition name="fade">
        <div class="FooterNav">
          <FooterNav :array="whitePagesArray" />
        </div>
      </transition>
      <!-- FOOTER END -->
    </div>

    <aside>
      <transition name="fadeLink">
        <div
          v-show="displayLeft"
          class="prev"
          :style="{ top: mouse.y + 'px', left: mouse.x + 'px' }"
        >
          {{ $t("Prev") }}
        </div>
      </transition>

      <transition name="fadeLink">
        <div
          v-show="displayRight"
          class="next"
          :style="{ top: mouse.y + 'px', left: mouse.x + 'px' }"
        >
          {{ $t("Next") }}
        </div>
      </transition>
    </aside>
  </div>
</template>
<style lang="scss" scoped>
* {
  z-index: 1;
}

.fadeLink-enter-active,
.fadeLink-leave-active {
  transition: opacity 0.3s;
}

.fadeLink-enter,
.fadeLink-leave-to {
  opacity: 0;
}

.mainLoader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 30px 0 0 0;
  background: #101010;
  z-index: 44;
  &__text {
    font-size: 1.2rem;
    position: fixed !important;
    top: 1rem;
    left: 2.5rem;
    color: #fff;
  }
}

.white {
  background-color: #ffffff;
  min-height: 100vh;
}

.backLink {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 30px;
  z-index: 33;

  &__link {
    min-width: 250px;
    text-align: center;
    a {
      color: $black;
      text-decoration: none;
    }
  }
}

.prev {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #000;
  pointer-events: none;
  z-index: 5;
}

.next {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #000;
  cursor: pointer;
  z-index: 5;
  pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.FooterNav {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media (max-width: 1023px) {
  .FooterNav {
    display: block;
  }

  .backLink {
    padding: 20px;
    justify-content: space-between;
    &__link {
      text-align: left;
    }
  }

  .mainLoader {
    &__text {
      left: 1.3rem;
      font-size: 1.5rem;
    }
  }

  .next,
  .prev {
    display: none;
  }
}

.children {
  &__button {
    position: fixed;
    bottom: 0px;
    width: 10%;
    height: 100%;
    z-index: 33;
    cursor: pointer;

    @include adaptive(mob) {
      display: none;
    }

    &_left {
      left: 0px;
    }

    &_right {
      right: 0px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 180px;
  padding-bottom: 60px;

  .copyright {
    font-size: 14px;
    color: $darkGrey;
    @extend %default-text;
  }

  @include adaptive(mob) {
    margin-top: 140px;
  }
}

.Header {
  background: #000;
}

@media (max-width: 385px) {
  .backLink {
    &__link {
      min-width: 120px;
      text-align: left;
    }
    &__language {
      margin-left: 20px;
    }
  }
}
</style>
